<template>
  <div class="organ-management-page" v-loading="loading">
    <el-card shadow="always">
      <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small" style="display:flex;margin-top:-2px;">
        <el-form-item label="客户姓名:" style="flex:1">
          <el-input v-model="form.area" :clearable="true" placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input
            v-model="form.phone"
            :clearable="true"
            placeholder="请输入客户手机号"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间:" style="flex:1;margin-left:30px">
          <el-date-picker v-model="form.value1" type="date" placeholder="选择开始时间" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:20px;flex:1">
          <el-button type="primary" @click="queryclick" icon="el-icon-search" :disabled="disabledFlag">查询</el-button>
          <el-button type="primary" @click="showNewWallte" icon="el-icon-upload2">导入</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="card" style="margin-top:20px">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="addNew('', 1)" plain icon="el-icon-plus">添加</el-button>
        <el-button type="danger" @click="delclick" plain icon="el-icon-delete">删除</el-button>
      </div>
      <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
      <!-- <div class="table-block"> -->
      <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center', color: '#999', background: '#EEF1FC' }"
        :cell-style="{ 'text-align': 'center' }"
        ref="multipleTable"
        height="442"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="handleClickTableRow"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="customerName" label="客户姓名"> </el-table-column>
        <el-table-column prop="tel" label="客户手机号"> </el-table-column>
        <el-table-column prop="balance" label="账户余额(￥)"> </el-table-column>
        <el-table-column prop="integral" label="账户积分"> </el-table-column>
        <el-table-column prop="updateTime" label="充值时间"> </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button @click="toggle(scope.row)" type="text" size="small">查看</el-button>
            <el-button @click="Record(scope.row)" type="text" size="small" style="margin-left:10px;">消费记录</el-button>
            <el-button @click="integral(scope.row)" type="text" size="small" style="margin-left:10px;">积分明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="padding: 20px 0"
        background
      >
      </el-pagination>
    </el-card>
    <!-- </div> -->
    <!-- 消费记录 -->
    <el-dialog title="消费记录" :visible.sync="dialogVisible" width="50%" :before-close="RecordClose" v-loading="consumeloading">
      <el-card style="margin-top: -20px;height: 400px;overflow: auto;">
        <el-table
          :data="recordData"
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="customerName" label="客户姓名" width="160"> </el-table-column>
          <el-table-column prop="amount" label="金额(￥)" width="120"> </el-table-column>
          <el-table-column prop="createTime" label="时间"> </el-table-column>
          <el-table-column prop="type" label="事项" width="100"> </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-card>
    </el-dialog>
    <!-- 积分明细 -->
    <el-dialog title="积分明细" :visible.sync="integralVisible" width="50%" :before-close="integralClose" v-loading="integralLoading">
      <el-card style="margin-top: -20px;height: 400px;overflow: auto;">
        <el-table
          :data="integralData"
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="customerName" label="客户姓名" width="160"> </el-table-column>
          <el-table-column prop="integral" label="积分" width="120"> </el-table-column>
          <el-table-column prop="createTime" label="时间"> </el-table-column>
          <el-table-column prop="reason" label="事项" width="100"> </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="integralVisible = false">取 消</el-button>
          <el-button type="primary" @click="integralVisible = false">确 定</el-button>
        </span>
      </el-card>
    </el-dialog>
    <importNewWallet @closeImportNewWallet="closeImportNewWallet" v-if="showImportNewWallet"></importNewWallet>
    <addNewWallte @closeAddNewWallte="closeAddNewWallte" v-if="showAddNewWallet"></addNewWallte>
    <walletOperation
      @closeWalletOperation="closeWalletOperation"
      v-if="showWallerOperation"
      :WallerOperation="WallerOperationData"
    ></walletOperation>
  </div>
</template>

<script>
import importNewWallet from '@/views/orderPurse/components/importNewWallet'
import walletOperation from '@/views/orderPurse/components/walletOperation'
import addNewWallte from '@/views/orderPurse/components/addNewWallte'
import { postRequestAnthor } from '@/api/index'
import { numberFormat } from '@/utils/utils'
export default {
  data() {
    return {
      form: {
        area: '',
        type: '',
        phone: '',
        value1: '',
      },
      tableData: [],
      multipleSelection: [],
      recordData: [],
      integralData:[],
      dialogVisible: false,
      showImportNewWallet: false,
      showAddNewWallet: false,
      showWallerOperation: false,
      total: 0,
      currentPage: 1,
      pagesize: 8,
      WallerOperationData: '',
      loading:true,
      disabledFlag:false,
      integralVisible:false,
      consumeloading:false,
      integralLoading:false
    }
  },
  components: { importNewWallet, addNewWallte, walletOperation },
  created() {
    this.lodadata(this.pagesize, this.currentPage)
  },
  methods: {
    // 获取页面数据
    lodadata(pageSize, pageNo, customerName, tel, updateTime) {
      postRequestAnthor('/customerwallet/querycustomerwalletlist', {
        pageSize,
        pageNo,
        customerName,
        tel,
        updateTime,
      }).then(res => {
        // console.log(res.data)
        this.total = res.count
        this.tableData = res.data
        this.tableData.map(obj=>{
          obj.balance=numberFormat(obj.balance, 2, ".", ",")
        })
        this.loading=false
      }).catch(()=>{
        this.loading=false
      })
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => {
        return item.id
      })
    },
    // 导入
    showNewWallte() {
      this.showImportNewWallet = true
    },
    // 查询
    queryclick() {
      if(this.form.area !="" || this.form.phone !="" || this.form.value1 !=""){
        this.loading=true
      this.disabledFlag = true
        this.lodadata('8', '1', this.form.area, this.form.phone, this.form.value1)
        setTimeout(()=>{
          this.disabledFlag = false
        },2000)
      }
    },
    // 删除
    delclick() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除这条记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postRequestAnthor('/customerwallet/deletes', { ids: this.multipleSelection }).then(res => {
              if (res == true) {
                this.lodadata(this.pagesize, this.currentPage)
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      }
    },
    // 查看
    toggle(row) {
      this.WallerOperationData = row
      this.showWallerOperation = true
    },
    // 添加
    addNew() {
      this.showAddNewWallet = true
    },
    // 消费记录
    Record(row) {
      // console.log(row)
      this.consumeloading = true
      this.dialogVisible = true
      postRequestAnthor('/customerwallet/querywalletdetaillist', { id: row.id }).then(res => {
        this.consumeloading = false
        // console.log(res);
        if(res.length>0){
          this.recordData = res
          this.recordData.map(obj=>{
          obj.amount=numberFormat(obj.amount, 2, ".", ",")         
        })
        }
      })
    },
    // 积分明细
    integral(row){
      this.integralLoading = true
      // console.log(row);
      this.integralVisible = true   
      postRequestAnthor('/customerwallet/queryintegraldetaillist', { customerId: row.customerId }).then(res => {
        this.integralLoading = false
        // console.log(res);
        if(res.length>0){
          this.integralData = res
          this.integralData.map(obj=>{
          obj.amount=numberFormat(obj.amount, 2, ".", ",")         
        })
        }
      })
    },
    closeAddNewWallte() {
      this.lodadata(this.pagesize, this.currentPage)
      this.showAddNewWallet = false
    },
    closeWalletOperation() {
      this.showWallerOperation = false
      this.lodadata(this.pagesize, this.currentPage)
    },
    closeImportNewWallet() {
      this.showImportNewWallet = false
      this.lodadata(this.pagesize, this.currentPage)
    },
    RecordClose() {
      this.recordData = []
      this.dialogVisible = false
    },
    integralClose(){
      // this.recordData = []
      this.integralVisible = false
    },
    handleSizeChange(val) {
      this.loading = true
      this.pagesize = val
      this.lodadata(this.pagesize, this.currentPage, this.form.area, this.form.phone, this.form.value1)
    },
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.lodadata(this.pagesize, this.currentPage, this.form.area, this.form.phone, this.form.value1)
    },
  },
}
</script>

<style lang="scss" scoped>
.search > .el-form-item {
  margin-right: 5px !important;
}
.organ-management-page {
  background: transparent;
}
.table-block {
  background-color: #fff;
}
.el-row {
  .el-form {
    display: flex;
    justify-content: space-between;
  }
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}

</style>
