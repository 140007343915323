
// export const imgaction = 'https://alanber.zhixunchelian.com/alanber_service/mobile/login/uploadFile' // 图片上传测试地址
// export const fileaction = 'https://alanber.zhixunchelian.com/alanber_service' //客户匹配文件上传测试地址
// export const filedownaction = 'https://alanber.zhixunchelian.com/alanber_service/file/download' // 文件下载测试地址
// export const fileuploadaction = 'https://alanber.zhixunchelian.com/alanber_service/mobile/login/uploadFile' // 文件上传测试地址
// export const fileuploada = 'https://alanber.zhixunchelian.com/alanber_service/customerwallet/parseExcel?type=customerWallet' // 钱包充值导入测试地址
// export const filedownload = 'https://alanber.zhixunchelian.com/alanber_service/file/download' // 钱包充值导出测试地址


// export const fileaction = 'http://192.168.8.86:10088/alanber_service' //客户匹配文件上传开发地址
// export const imgaction='http://192.168.8.86:10088/alanber_service/mobile/login/uploadFile' // 图片上传开发地址
// export const filedownaction = 'http://192.168.8.207:10088/alanber_service/file/download' // 文件下载开发地址
// export const fileuploadaction = 'http://192.168.8.86:10088/alanber_service/mobile/login/uploadFile' // 文件上传开发地址
// export const fileuploada = 'http://192.168.8.86:10088/alanber_service/customerwallet/parseExcel?type=customerWallet' // 钱包充值导入开发地址
// export const filedownload = 'http://192.168.8.86:10088/alanber_service/file/download' // 钱包充值导出开发地址


export const fileaction = 'https://manage.alanberclinic.com/alanber_service/' //客户匹配文件上传生产环境
export const imgaction='https://manage.alanberclinic.com/alanber_service/mobile/login/uploadFile' // 图片上传生产环境
export const filedownaction =   'https://manage.alanberclinic.com/alanber_service/file/download' // 文件下载生产环境
export const fileuploadaction = 'https://manage.alanberclinic.com/alanber_service/mobile/login/uploadFile' // 文件上传生产环境
export const fileuploada = 'https://manage.alanberclinic.com/alanber_service/customerwallet/parseExcel?type=customerWallet' // 钱包充值导入生产环境
export const filedownload = 'https://manage.alanberclinic.com/alanber_service/file/download' // 钱包充值导出生产环境
