<template>
  <!-- 导入 -->
  <el-dialog
    title="导入/导出"
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-card style="margin-top: -20px">
      <div class="title">
        <el-upload
          class="upload-demo"
          :action="fileuploada"
          :on-success="success"
          :show-file-list="falg"
          style="margin-right: 10px"
        >
          <el-button size="small" type="primary" icon="el-icon-upload2">导入</el-button>
        </el-upload>
        <el-button type="success"><a :href="downhrefurl" class="download">下载模板</a></el-button>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#999' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="customerName" label="姓名"> </el-table-column>
          <el-table-column prop="tel" label="电话号码 "> </el-table-column>
          <el-table-column prop="customerNo" label="会员号"> </el-table-column>
          <el-table-column prop="amount" label="充值金额"> </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer" style="text-align: center; margin-top: 20px">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addImport">确 定</el-button>
        </div>
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import { postRequestAnthor } from '@/api/index'
import { fileuploada, filedownload } from 'src/utils/cities'
export default {
  data() {
    return {
      dialogVisible: true,
      tableData: [],
      falg: false,
      downhrefurl: '',
      fileuploada: `${fileuploada}&computerToken=${JSON.parse(localStorage.getItem('newToken')).computerToken}`,
      filedownload: filedownload,
    }
  },
  created() {
    // 获取下载模板地址
    axios
      .get(this.filedownload, {
        params: {
          customerWallet: 'customerWallet',
          type: 'customerWallet',
        },
      })
      .then(res => {
        this.downhrefurl = res.config.url
      })
  },
  methods: {
    handleClose() {
      this.$emit('closeImportNewWallet')
    },
    // 导入
    success(file) {
      console.log(file)
      if (file.code == 200) {
        this.tableData = file.data
        this.$message({
          message: '文件导入成功',
          type: 'success',
        })
      }
    },
    // 确定导入
    addImport() {
      if (this.tableData.length >= 1) {
        postRequestAnthor('/customerwallet/batchrecharge', {
          datas: this.tableData,
          createBy: this.tableData.length,
        }).then(res => {
          if (res > 0) {
            this.$message({
              type: 'success',
              message: '客户信息导入成功',
            })
            this.dialogVisible = false
          }
          console.log(res)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-table {
  max-height: 400px;
  overflow: auto;
}
.title {
  display: flex;
  .el-button {
    text-align: center;
  }
}
.table {
  margin-top: 20px;
}
.download {
  color: #fff;
  text-decoration: none;
}
</style>