<template>
  <!-- 添加 -->
  <el-dialog :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" @close="wallteClose" :showClose="showclose">
    <div style="margin-top:-30px">
      <el-card class="box">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="24" style="text-align: center">
              <el-autocomplete
                v-model="inputsearch"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入客户姓名/电话"
                @select="handleSelect"
              ></el-autocomplete>
            </el-col>
          </el-row>
          <el-row style="padding: 20px 20% 0;margin:0;">
            <el-col :span="12">
              <el-col>客户姓名:{{ adduserdata.customerName }}</el-col>
            </el-col>
            <el-col :span="12">
              <el-col>客户电话:{{ adduserdata.tel }}</el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="钱包充值" name="wallet">
            <el-form ref="form" :model="form" label-width="80px" style="background-color: #fff; padding: 20px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="缴费时间:">
                    <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="form.date1"
                      style="width: 100%"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="方式:">
                    <el-select v-model="selectvalue" placeholder="请选择">
                      <el-option label="充值" value="1"> </el-option>
                      <el-option label="缴费" value="2"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="金额:">
                    <el-input v-model="form.name" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" placeholder="￥"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> </el-form
          ></el-tab-pane>
          <el-tab-pane label="新增积分" name="integral"
            ><el-form ref="form" :model="form" label-width="80px" style="background-color: #fff; padding: 20px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="添加时间:">
                    <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="form.integraldate"
                      style="width: 100%"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="积分:">
                    <el-input v-model="form.integralNum" placeholder="积分数" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注:">
                    <el-input v-model="form.integralval" placeholder="备注"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> </el-form
          ></el-tab-pane>
        </el-tabs>
        </el-card>
      </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addclick" :disabled="disabled">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      form: {
        date1: '',
        integraldate: '',
        integralNum: '',
        integralval: '',
        name: '',
      },
      dialogVisible: true,
      selectvalue: '',
      activeName: 'wallet',
      restaurants: [],
      state: '',
      timeout: null,
      inputsearch: '',
      adduserdata: '',
      showclose:false,
      disabled:false
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      let data = {
        customerName: queryString,
      }
      postRequestAnthor('/customerwallet/queryCustomer', data).then(res => {
        // console.log(res)
        // this.userlist = res
        // this.form.status = res[0].customerProduct[0].productName
        // this.selectlist = res[0].customerProduct[0].productRights
        // this.form.selectstatus = this.selectlist[0].dictionaryName
        // console.log(this.selectlist);
        for (let i = 0; i < res.length; i++) {
          res[i].value = res[i].customerName +"  "+res[i].tel
        }
        cb(res)
      })
    },
    handleSelect(item) {
      console.log(item)
      this.userlist = item
      this.adduserdata = item
    },
    // 确认添加
    addclick() { 
      if(this.adduserdata != ""){
        this.disabled = true
      setTimeout(()=>{
        this.disabled = false
      },1500)
            if (this.activeName == 'wallet') {
        // 充值
        if(this.form.date1 == ''){
          this.$message({
          message: '请选择充值时间',
          type: 'warning'
        });
        }else if(this.selectvalue == ""){
          this.$message({
          message: '请选择充值方式',
          type: 'warning'
        });
        }else if(this.form.name == ""){
          this.$message({
          message: '输入充值金额',
          type: 'warning'
        });
        }else if(this.form.name != "" && this.selectvalue != "" && this.form.date1 != ""){
        postRequestAnthor('/customerwallet/recharge', {
          customerId: this.adduserdata.id,
          amount: this.form.name,
          type: this.selectvalue,
          createBy: 1,
          updateTime: this.form.integraldate,
          updateBy: 1,
        }).then(res => {
          if (res == true) {
            this.$message({
              message: '缴费成功',
              type: 'success',
            })
            this.dialogVisible = false
          }
        })}
      } else if (this.activeName == 'integral') {
        // 新增积分
        if(this.form.integraldate == ''){
          this.$message({
          message: '请选择添加时间',
          type: 'warning'
        });
        }else if(this.form.integralNum == ""){
          this.$message({
          message: '输入新增积分数',
          type: 'warning'
        });
        }else if(this.form.integraldate != "" && this.form.integralNum != "" ){
          console.log(this.form.integraldate, this.form.integralNum,this.form.integralval,this.adduserdata.id);
        postRequestAnthor('/customerwallet/addintegral', {
          integral: this.form.integralNum,
          reason: this.form.integralval,
          customerId: this.adduserdata.id,
          updateTime: this.form.integraldate,
          create_by: 1,
        }).then(res => {
          if (res == true) {
            this.$message({
              message: '积分更改成功',
              type: 'success',
            })
            this.dialogVisible = false
          }
        })}
      }
      }
  
    },

    handleClick(tab) {
      this.tabname = tab.name
      // console.log(tab.name)
    },
    wallteClose() {
      this.$emit('closeAddNewWallte')
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.box {
  display: flex;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 20px;
  :first-child {
    flex: 1;
  }
}
.button {
  text-align: center;
}
</style>